import { useCallback } from 'react';
import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { getIn } from 'formik';

export default function (Primitive) {
  const componentBuilder = ({ field, form, className, onChangeDate, ...rest }) => {
    const { name, value } = field;
    const { setFieldValue, setFieldTouched, errors, touched } = form;
    const isValid = !getIn(touched, name) || !getIn(errors, name);

    const onChange = useCallback(date => {
      //127902_3.6_Requested_delivery_block_weekends
      var dayOfWeek = new Date(date).getDay();
      var isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0); // 6 = Saturday, 0 = Sunday
      var isCheckoutPage = window.location.pathname.endsWith("/checkout");
      if ((isCheckoutPage && !isWeekend) || !isCheckoutPage) {
        setFieldTouched(name, true, false);
        setFieldValue(name, date);

        //[127904] [Palfinger] 3.8. Discount based on requested delivery date and shipping method
        //[158841] Order history/ From date / To-date Error
        if (onChangeDate) {
          onChangeDate(date);
        }
      }
    }, [name, setFieldValue, setFieldTouched]);

    return (
      <Primitive
        name={name}
        {...rest}
        value={value}
        onChange={onChange}
        isValid={isValid}
      />
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  const DateField = ({ fieldName, fieldTitle, validation, initialValue, minDate, maxDate, ...props }) => {
    const dateValidation = {
      ...validation,
      date: true,
      datesRange: { minDate, maxDate },
    };

    return (
      <Field
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={dateValidation}
        component={componentBuilder}
        initialValue={initialValue || null}
        minDate={minDate}
        maxDate={maxDate}
        {...props}
      />
    );
  };

  DateField.propTypes = fieldPropTypes;

  return DateField;
}
